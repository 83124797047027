<template>
  <div>
    <HeaderComp />
    <b-jumbotron class="jumboDark">
      <b-container>
        <b-row class="justify-content-center" style="margin-bottom: 30px">
          <b-col cols="12" style="margin-top:60px;">
            <div class="mem_img main_header"></div>
            <!-- <img src="../assets/230717, Membership.png" class="resImg" /> -->
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row class="justify-content-center">
          <b-col cols="12">
            <h1 class="ttl" > 
              <font color="white" > BE YOUR </font>
              <font color="aqua"> BEST </font>
            </h1>
          </b-col>  
        </b-row>
        <b-row class="justify-content-center" style="margin-bottom: 30px">
          <b-col cols="10">
            <p style="text-align: center; font-size: larger">
              Your training may evolve over time. We want to evolve with you.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>
    <MembershipTypesVertical />
    <MembershipPricing />
    <LaunchBanner />
    <GroupClassTypes />
    <PolicyBanner />
    <button @click="scrollToTop" class="scroll-to-top"><b-icon-arrow-up/></button>
    <FooterComp />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue";
import FooterComp from "@/components/FooterComp.vue";
import PolicyBanner from "@/components/PolicyBanner.vue";
import GroupClassTypes from "@/components/GroupClassTypes.vue";
import MembershipTypesVertical from "@/components/MembershipTypesVertical.vue";
import MembershipPricing from "@/components/MembershipPricing.vue";
import LaunchBanner from "@/components/LaunchBanner.vue"

export default {
  name: "MembershipView",
  components: {
    HeaderComp,
    FooterComp,
    PolicyBanner,
    GroupClassTypes,
    MembershipTypesVertical,
    MembershipPricing,
    LaunchBanner
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">

.litems {
  text-align: left;
}
.memContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}
.memOpt {
  max-width: 900px;
}
.mem_img {
  background-image: url('../assets/img_plank_wide.png');
}

// for mobile displays
@media (max-width: 700px) {
  #classLabel {
    display: none;
  }
  .memOpt {
    padding: 0;
  }
  .showWidget {
    visibility: hidden;
    height: 10px;
  }
}
</style>
