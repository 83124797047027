<template>
    <div >
        <b-container style="padding-top:90px; padding-bottom:90px;  color:black;">
            <b-row class="justify-content-center">
                <b-col cols="10" lg="8"> 
                    <h1 class="ttl"> SMALL GROUP CLASSES </h1>
                    <div class="lineBreak" style="margin-top: -10px;"></div>
                    <p style="color: gray; margin-top: -20px;"> All classes are an hour long and capped to 12 people. </p>
                </b-col>
            </b-row>
            
            <b-row class="justify-content-center align-items-center" style="margin-top:40px;">
                <b-col cols="6" md="2">
                    <img src="../assets/summitStrength.png" style="width:100%; height: auto; max-width: 250px;">
                </b-col>
                <b-col cols="10" md="8">
                    <h3 class="classLabel"> Summit Strength </h3>
                    <p> Our most popular class, Summit Strength a mix of challenging compound movements that involve 
                        dumbbells, kettlebells, barbells, and bodyweight training.  Expect challenging primary movements to be paired 
                        with accessory work to balance out this whole body training program.   
                    </p>
                    <p style="color:gray"> M/W/F 5:45am, 7am, 8:15am, 12pm, 4:15pm, 5:30pm </p>
                    <p style="color:gray"> T/TH 5:45am, 7am, 12pm, 4:30pm </p>
                </b-col>
            </b-row>
            
            <b-row class="justify-content-center align-items-center" style="margin-top:40px;">
                <b-col cols="6" md="2" >
                <img src="../assets/metabolicStrength.png" style="width:100%; height: auto; max-width: 250px;">
                </b-col>
                <b-col cols="10" md="8" >
                <h3 class="classLabel"> Metabolic Strength </h3>
                <p> Welcome to Uplift Boulders rendition of a conditioning class. 
                    Metabolic Strength is rooted in strength based movements paired with heart 
                    rate inducing movements to make for a well rounded, total body workout. 
                    Expect partner work, EMOM and circuit style workouts in this class. 
                    We will only use free weights, cardio equipment, bands and wall balls.   
                </p>
                <p style="color:gray"> T/TH 6am, 5:30pm </p>
                </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center" style="margin-top:40px;" >
                <b-col cols="6" md="2">
                <img src="../assets/mobility.png" style="width:100%; height: auto; max-width: 250px;">
                </b-col>
                <b-col cols="10" md="8">
                <h3 class="classLabel"> Mobility </h3>
                <p> We will use a range of different mobility techniques that help you slowly open the body 
                    throughout a session, and with consistency, will build strength and flexibility everywhere. 
                    The intention is that each person comes out of class feeling more limber and having more 
                    understanding of their body, and that we have some fun in the process! 
                </p>
                <p style="color:gray"> T/TH 5:30pm </p>
                </b-col>
            </b-row>
            <b-row class="justify-content-center  align-items-center" style="margin-top:40px;">
                <b-col cols="6" md="2" >
                <img src="../assets/weekendHybrid.png" style="width:100%; height: auto; max-width: 250px;">
                </b-col>
                <b-col cols="10" md="8" >
                <h3 class="classLabel"> Weekend Hybrid </h3>
                <p> Looking for something more? Us too!  That's why we created the weekend hybrid.  These classes are meant to fill
                    in the gaps, encourage some teamwork and give the weekend a little spice!
                </p>
                <p style="color:gray"> Sat/Sun 9am </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template> 

<script>

export default {
  name: 'GroupClassTypes',
  components: {

  },
  data() {
    return {

    
    }
  
  },
  methods: {

    
  }
  

}
</script>
<style>


@media (max-width: 700px) {
  .classLabel {
    display: none;
    
  }
}
</style>

  