<template>
    <div >
      <HeaderComp />
      <b-jumbotron class="jumboDark" >
        <b-container >
          <b-row class="justify-content-center" style="margin-bottom:30px;">
          <b-col cols="12" style="margin-top:30px;">
            <div class="events_img main_header"></div>
          </b-col>
        </b-row>
        </b-container>
        <b-container>
          <b-row class="justify-content-center" >
          <b-col cols="12">
            <h1 class="ttl" > 
              <font color="white" weight="bold"> UPCOMING </font>
              <font color="aqua"> EVENTS </font>
            </h1>
          </b-col>  
        </b-row>
          <b-row class="justify-content-center" style="margin-bottom:60px;">
            <b-col cols="8" >
              <p style="text-align: center; font-size: larger;"> 
                Specialty classes, team activities, and community outreach hosted by Uplift. </p>
            </b-col>  
          </b-row>
          
        </b-container>
      </b-jumbotron>

      <b-jumbotron class="jumboDark">
        <b-container>
                    
          <b-row class="align-content-center" >
            <b-col cols="11" sm="6" > 
              <p class="eventFeatDate" > August 24th </p>
              <p class="eventFeatTitle" > Pull Up Seminar  </p>
              <p class="eventFeatTime" > 10:30 - 11:30am </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                Have you felt stuck with your progress on the pull up or chin up?  <br>
                Are you wondering how to progress through the different levels of assisted pull/chin ups in class?  <br><br>
                This is the seminar for you! In this informative hour, we will troubleshoot how to progress and appropriately 
                challenge your pull/chin ups.  
                If you are looking to either achieve your first unassisted rep, double the amount of reps you can currently do, 
                or something even more ambitious, join us for this seminar! <br><br>
                <b>Prerequisites:</b> You need to be able to hang unassisted for 30 seconds and perform pull ups 
                (assisted or not) without the movement going solely to your neck.  
                <i>This is a donation based seminar- 
                  please bring a non-perishable food item for Harvest of Hope or make a 
                  monetary donation to Boulder Food Rescue. This seminar is open to the public.
                </i>
              </p>
              <b-btn href="https://www.wellnessliving.com/schedule/uplift_boulder?id_class_tab=1&k_class=634798&k_class_tab=38437" 
                target="_blank" 
                style="background-color:rgb(136, 0, 255) !important; color: black !important; font-size: 22px !important;"> 
                Sign up for FREE
              </b-btn>
            </b-col>
          </b-row>
          <div style="margin-bottom:120px;"></div>
          
          <!-- <b-row class="align-content-center" >
            <b-col cols="11" sm="6" > 
              <p class="eventFeatDate" > September 27th </p>
              <p class="eventFeatTitle" > Annual Party </p>
              <p class="eventFeatTime" > TBD </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                Join us in celebrating 3 years of Uplift!  
              </p>
              <b-btn href="https://tr.ee/n7BPLUOLt3" 
                target="_blank" 
                style="background-color:rgb(136, 0, 255) !important; color: black !important; font-size: 22px !important;"> 
                Register
              </b-btn>
            </b-col>
          </b-row>
          <div style="margin-bottom:120px;"></div> -->

          <!-- 
            
            NEW CLASSES 
          
          -->
          <!-- <b-row>  
            <b-col cols="12">
              <h1 class="ttl" > 
                <font color="white" weight="bold"> NEW </font>
                <font color="aqua"> CLASSES </font>
              </h1>
            </b-col>  
          </b-row>
          <b-row class="align-content-center" >
            <b-col cols="11" sm="6"> 
              <p class="eventFeatTitle" > Booty Camp </p>
              <p class="eventFeatTime" > Mon/Thu 9:45am </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                Coach Jena is pretty passionate about the peaches and has been wanting to run a women’s only, peaches focused group for some time now. 
                Introducing “Booty Camp” <br> <br>
                <b> What is Booty Camp? </b> <br>
                  - small group <br>
                  - women’s only <br>
                  - total body + booty focused training program <br>
              </p>            
            </b-col>
          </b-row>
          <div style="margin-bottom:120px;"></div> -->
          <!-- 
            
            NEW Challenge 
          
          -->
          <b-row>  
            <b-col cols="12">
              <h1 class="ttl" > 
                <font color="white" weight="bold"> MONTHLY </font>
                <font color="aqua"> CHALLENGE </font>
              </h1>
            </b-col>  
          </b-row>
          <b-row class="align-content-center" >
            <b-col cols="11" sm="6"> 
              <p class="eventFeatTitle" > Step Up Challenge </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                How many steps can you do in 3 minutes on an 18 inch box? <br>
                Weight tiers: <br>
                Tier 1: body weight <br>
                Tier 2: 15/25 lbs each hand <br>
                Tier 3: 25/35 lbs each hand <br>
                Every foot that goes up = 1 rep, must alternate <br>
              </p>            
            </b-col>
          </b-row>
          <div style="margin-bottom:120px;"></div>
        <!-- END -->
        </b-container>
      </b-jumbotron>
      
      <!-- <InstagramPost /> -->
      <TestimonialsBanner2 />
      <LaunchBanner />
      <button @click="scrollToTop" class="scroll-to-top"><b-icon-arrow-up/></button>
      <FooterComp />
    </div>
</template> 

<script>
import HeaderComp from '@/components/HeaderComp.vue'
import FooterComp from '@/components/FooterComp.vue'
import LaunchBanner from "@/components/LaunchBanner.vue"
import TestimonialsBanner2 from '@/components/TestimonialsBanner2.vue'

export default {
  name: 'EventsView',
  components: {
    HeaderComp,
    FooterComp,
    LaunchBanner,
    TestimonialsBanner2,
  },
  data() {
    return {
    };
  },
  methods: {
    
  },
  

}
</script>
<style scoped lang="scss">

.events_img {
  background-image: url('../assets/img_events.png');
}
.eventFeat
{
  background-color: black;
  border-color: cyan;
  border-style: double;
  border-width: 20px;
  border-radius: 5px;
}
.eventFeatImg
{
  border: 5px solid rgb(255, 217, 0);
  border-radius: 4px;
  padding: 5px;
  width: 90%;
  height: auto;
  max-width: 600px;
  min-width: 200px;
  margin: auto;
  justify-self: center;
}
.eventFeatCont
{
  margin-left: 0;
  margin-bottom: 30px;
  justify-self: center;
  text-align: left;

}
.eventFeatDate
{
  font-variant-caps: all-small-caps; 
  font-size:2rem;
  margin-bottom:0; 
  font-weight:bold; 
  color:#e9e9e9;
;
}
.eventFeatTime
{
  font-size:1.5rem;
  margin-bottom:0; 
  color:cyan;
}
.eventFeatTitle
{
  margin-bottom:0;
  font-family: 'comfortaa';
  font-weight:bold; 
  color: rgb(255, 255, 255);
  font-size: 2rem;
}

.eventFeatDesc
{
  margin-bottom:15px;
  color:#e9e9e9;
  font-size: 1rem;
  text-align: left;
}

.eventFeatPrice
{
  font-size:1rem;
  margin-bottom:30px; 
  font-weight:500; 
  color:cyan;
}


.eventBreak
{
  width: 100px;
  transform: skewX(-20deg);
  border-bottom: 30px solid cyan;
  margin: auto 5px;
  
}
.eventBreakCircle
{
  height: 20px;
  width: 20px;
  background-color: cyan;
  border-radius: 50%;
  margin: auto 5px;
  display: inline-block;
}
.eventCont
{
  width: 300px;
  margin: auto;
  justify-self: center;
  text-align: left;
}
.eventDate
{
  font-variant-caps: all-small-caps; 
  font-size:1.5rem;
  margin-bottom:0; 
  font-weight:bold; 
  color:#7c7c7c;
}
.eventTime
{
  margin-bottom:0; 
  font-size:1.5rem;
  font-weight:bold; 
  color:darkcyan;
}
.eventTitle
{
  margin-bottom:0;
  font-family: 'comfortaa';
  font-weight:bold; 

  color: black;
  font-size:2rem;
}

.eventDesc
{
  margin-bottom:0;
  color:#7c7c7c;
  font-size:1.5rem;
}

.eventPrice
{
  margin-bottom:0; 
  font-weight:500; 
  color:darkcyan;
  font-size:1.5rem;
}
.eventInfoReq
{
  text-align: center;
  margin-top: 20px;
  margin-bottom:0;
  color:black;

}

.eventImg
{
  width: 90%;
  height: auto;
  max-width: 400px;
  min-width: 200px;
  margin: auto;
  justify-self: center;
}


@media (min-width: 768px) {
  .eventFeatCont
  {
    margin-left: 15px;
  }
  .eventCont
  {
    width: 400px;
  }
  .eventBreak
  {
    width: 300px;
  }
  .eventBreakCircle
  {
    height: 30px;
    width: 30px;
  }
}
</style>

  