<template>
    <!-- Group class widget from wellneess living -->
    <div >
        <b-jumbotron class="jumboLight " style="padding-top: 60px; padding-bottom: 60px;">
            <div style="width:95%; max-width: 800px; text-align: center; justify-content: center; margin: auto;"> 
                <h1 class="ttl"> Group Class Schedule </h1>
                <div class="lineBreak" style="margin-top: -10px; "></div>
            </div>
            <div style="width:100%; justify-content:center;" v-html="wlWidgetCusom">

            </div>
        </b-jumbotron>
    </div>
</template>
<script>
    export default {
        name: 'WLGroupClass',
        data() {
            return {
                wlWidgetCusom: '<div class="wl-widget" data="k_skin=197851&amp;k_business=161411"></div>'
            }
        },
        mounted() {
            let wlWidget = document.createElement('script');
            wlWidget.setAttribute('src', 'https://www.wellnessliving.com/rs/skin-widget-static.js');
            wlWidget.setAttribute('type', 'text/javascript');
            document.head.appendChild(wlWidget)
        },
    }
</script>
